import React, { useState, useEffect } from "react";
import {
  Box,
  Modal,
  TextareaAutosize,
  IconButton,
  Button,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../context/AuthContext";

const NotesModal = ({
  item,
  isEditable,
  isOpen,
  onClose,
  saveNotesCB,
  currListItemID,
}) => {
  const [textContent, setTextContent] = useState("");

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:601px) and (max-width:900px)"
  );
  const { userID } = useAuth();

  useEffect(() => {
    setTextContent(item?.notes);
  }, [item]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: isSmallScreen ? "10px" : "20px",
          borderRadius: "8px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={onClose} aria-label="close">
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        <TextareaAutosize
          readOnly={!isEditable}
          value={textContent}
          onChange={(e) => setTextContent(e.target.value)}
          style={{
            width: isSmallScreen ? "80vw" : "90vw",
            maxWidth: isMediumScreen ? "500px" : "600px",
            padding: isSmallScreen ? "8px" : "12px",
            borderRadius: "6px",
            resize: "none",
            fontSize: isSmallScreen ? "18px" : isMediumScreen ? "26px" : "30px",
          }}
        />

        <Box sx={{ display: "flex" }}>
          {userID !== "" && currListItemID && (
            <Button
              variant="contained"
              onClick={() => saveNotesCB(textContent)}
              style={{ marginTop: isSmallScreen ? "8px" : "10px" }}
            >
              Save
            </Button>
          )}
        </Box>
      </div>
    </Modal>
  );
};

export default NotesModal;
