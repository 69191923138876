import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Link,
  Grid,
  Typography,
  Container,
  CssBaseline,
  CircularProgress,
} from "@mui/material";

import { useAuth } from "../context/AuthContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme();

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { dispatchUserEvent, isLoading, setLoading } = useAuth();

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    dispatchUserEvent(
      "RESETPASS",
      {
        email: email,
      },
      () => navigate("/adminlogin")
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="xs"
        style={{
          backgroundColor: "white",
          minHeight: "100vh",
          minWidth: "100vw",
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            paddingTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            onClick={() => navigate("/")}
            variant="h4"
            noWrap
            sx={{
              cursor: "pointer",
              fontFamily: "monospace",
              letterSpacing: ".3rem",
              color: "black",
              textDecoration: "none",
            }}
          >
            LA LISTA
          </Typography>

          <Box noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo Electronico"
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Restablecer la Contraseña
            </Button>

            {isLoading && (
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}
            <Grid container>
              <Grid item xs>
                <Link
                  onClick={() => navigate("/adminlogin")}
                  variant="body2"
                  sx={{ cursor: "pointer" }}
                >
                  Regresa a Iniciar Sesion
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ForgotPassword;
