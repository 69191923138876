import React from "react";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";

var Android = /(android)/i.test(navigator.userAgent);
var iOS = /(iPad|iPhone|iPod)/i.test(navigator.userAgent);

const PrintChordSheet = ({ title, text }) => {
  const printTextArea = () => {
    try {
      const printWindow = window.open("", "_blank");

      // Add a back button to go back to the previous page
      printWindow.document.write(
        `<html><head><style>
        .back-button {
          background-color: #4CAF50;
          border: none;
          color: white;
          padding: 15px 32px;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          font-size: 16px;
          margin: 4px 2px;
          cursor: pointer;
        }
        @media print {
          .back-button {
            display: none;
          }
        }
      </style><title>${title}</title></head><body style='font-family: 'Roboto Mono', monospace'>`
      );
      printWindow.document.write(
        `<div style="font-size:25px;font-family:Arial;text-align:center;">${title}</div>`
      );
      printWindow.document.write(`<pre style='font-size:15px'>${text}</pre>`);
      if (Android || iOS)
        printWindow.document.write(
          `<center><button class="back-button" onclick="window.close()">Close</button></center>`
        );
      printWindow.document.write("</body></html>");

      printWindow.document.close();
      printWindow.print();
      if (!Android && !iOS) printWindow.close();
    } catch (error) {
      console.error("Error while printing:", error);
    }
  };

  return (
    <IconButton
      sx={{ mx: 1 }}
      onClick={printTextArea}
      aria-label="print"
      size="large"
    >
      <PrintIcon sx={{ transform: "scale(1.8)" }} />
    </IconButton>
  );
};

export default PrintChordSheet;
