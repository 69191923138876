import React, { useState } from "react";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import {
  useMediaQuery,
  Box,
  Button,
  TextField,
  Modal,
  Typography,
  CircularProgress,
} from "@mui/material";
import moment from "moment";

import { addOtherListItem } from "../utils/DBFunctions";

const StoreListModal = ({ data, open, onClose, isLoading, setLoading }) => {
  const [dateSelected, setDateSelected] = useState(moment());
  const [newListTitle, setNewListTitle] = useState("");

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const handleSave = () => {
    addOtherListItem(
      {
        date: dateSelected.format("MM/DD/YYYY"),
        songs: data.songs,
        otherListLastAuthor: data.email,
        title: newListTitle,
      },
      (choice) => setLoading(choice)
    );
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: isSmallScreen ? "10px" : "20px",
          borderRadius: "8px",
        }}
      >
        <Typography>Ingrese los datos antes de almacenar la lista.</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            my: 2,
          }}
        >
          <MobileDatePicker
            label="Escoge Fecha"
            inputFormat="MM/DD/YYYY"
            value={dateSelected}
            onChange={(newValue) => setDateSelected(newValue)}
            slotProps={{ textField: { variant: "outlined" } }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            my: 2,
          }}
        >
          <TextField
            label="Titulo de La Lista (Opcional)"
            fullWidth
            value={newListTitle}
            onChange={(event) => setNewListTitle(event.target.value)}
          />
        </Box>
        <Button onClick={handleSave}>Save</Button>
        <Button onClick={onClose}>Close</Button>
        {isLoading && <CircularProgress />}
      </Box>
    </Modal>
  );
};

export default StoreListModal;
