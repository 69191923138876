import React from "react";
import { Button, Box } from "@mui/material";

const FontSizeAdjuster = ({ initialFontSize, currFontSize, setFontSize }) => {
  const increaseFontSize = () => {
    if (currFontSize < initialFontSize + 10) {
      // Maximum increase limit (initial + 10)
      setFontSize(`${currFontSize + 2}px`);
    }
  };

  const decreaseFontSize = () => {
    if (currFontSize > initialFontSize - 10) {
      // Minimum decrease limit (initial)
      setFontSize(`${currFontSize - 2}px`);
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
      <Button
        sx={{ mx: 1, fontSize: 15 }}
        variant="contained"
        color="secondary"
        onClick={increaseFontSize}
      >
        +
      </Button>

      <Button
        sx={{ mx: 1, fontSize: 20 }}
        variant="contained"
        color="secondary"
        onClick={() => setFontSize(`${initialFontSize}px`)}
      >
        ⟲
      </Button>

      <Button
        sx={{ mx: 1, fontSize: 15 }}
        variant="contained"
        color="secondary"
        onClick={decreaseFontSize}
      >
        -
      </Button>
    </Box>
  );
};

export default FontSizeAdjuster;
