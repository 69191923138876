import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Link,
  Grid,
  Typography,
  Container,
  CssBaseline,
} from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "../context/AuthContext";

const theme = createTheme();

const AdminLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { dispatchUserEvent, isLoading, setLoading } = useAuth();

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    dispatchUserEvent("LOGIN", { email, password }, () => navigate("/"));
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="xs"
        style={{
          backgroundColor: "white",
          minHeight: "100vh",
          minWidth: "100vw",
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            paddingTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            onClick={() => navigate("/")}
            variant="h4"
            noWrap
            sx={{
              cursor: "pointer",
              fontFamily: "monospace",
              letterSpacing: ".3rem",
              color: "black",
              textDecoration: "none",
            }}
          >
            LA LISTA
          </Typography>
          <Box noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo Electronico"
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.target.blur();
                  handleSubmit(e);
                }
              }}
            />
            <Button
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Iniciar Sesion
            </Button>
            {isLoading && (
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}
            <Grid container>
              <Grid item xs>
                <Link
                  onClick={() => navigate("/forgotpassword")}
                  variant="body2"
                  sx={{ cursor: "pointer" }}
                >
                  ¿Olvidaste tu contraseña?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default AdminLogin;
