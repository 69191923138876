import React from "react";

const LastModifiedByText = ({ email = "", time = "" }) => {
  return (
    <p style={{ margin: "20px 0px", textAlign: "center" }}>
      Ultima modificacion por {email} el {time}.
    </p>
  );
};

export default LastModifiedByText;
