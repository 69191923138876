import React, { useState } from "react";
import { DeviceUUID } from "device-uuid";
import { Box, Button, CircularProgress } from "@mui/material";
import ModalRepo from "../components/ModalRepo";
import { modifyVoteDB } from "../utils/DBFunctions";

export default function Vote() {
  const uuid = new DeviceUUID().get();

  const [selection, setSelection] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  return (
    <div
      style={{
        backgroundColor: "white",
        minHeight: "100vh",
      }}
    >
      <ModalRepo
        sendItemCB={(item) => setSelection(item)}
        open={open}
        setOpen={setOpen}
      />
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: 30,
          marginBottom: 30,
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "green",
            "&:hover": {
              backgroundColor: "darkgreen",
            },
          }}
          onClick={() => setOpen(true)}
        >
          Seleccionar Canto
        </Button>
      </Box>
      <h1 style={{ padding: "20px 20px", textAlign: "center" }}>
        {selection !== null ? selection.name : "-SELECCION AQUI-"}
      </h1>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 30,
          marginBottom: 30,
        }}
      >
        <Button
          variant="contained"
          onClick={() =>
            selection !== null
              ? modifyVoteDB(uuid, selection.name, setLoading)
              : setOpen(true)
          }
          disabled={selection === null}
        >
          Votar
        </Button>
      </Box>

      <h3 style={{ padding: "20px 20px", textAlign: "center" }}>
        NOTA: Todos los domingos se reinician los votos y se restablece la
        abilidad de votar.
      </h3>

      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 5,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
