import React, { useEffect, useMemo } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
} from "@mui/material";
import { Buffer } from "buffer";

const fetchAndSaveFile = async (item, setLoading) => {
  try {
    const response = await fetch(item.file);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const textContent = await response.text();
    const buff = Buffer.from(textContent, "utf-8");
    const base64Content = buff.toString("base64");

    const myObject = {
      url: item.file,
      data: base64Content,
    };

    window.localStorage.setItem(`${item.id}`, JSON.stringify(myObject));

    setLoading(false);
  } catch (error) {
    console.error("Error fetching or saving the file:", error);
  }
};

const SongList = ({
  setLoading,
  songs,
  setDocument,
  setCurrKey,
  setKeyPref,
  selectedIndex,
  setSelectedIndex,
  setVideoLink,
}) => {
  useEffect(() => {
    songs.forEach((item) => {
      fetchAndSaveFile(item, setLoading);
    });
  }, [songs, setLoading]);

  const handleListItemClick = (ID, video, key, keyPref, index) => {
    if (selectedIndex !== index) {
      setLoading(true);
      setSelectedIndex(index);
      const obj = JSON.parse(window.localStorage.getItem(`${ID}`));
      setDocument(obj.data);
      setCurrKey(key);
      setKeyPref(keyPref);
      setVideoLink(video);
    }
  };

  const memoizedSongs = useMemo(() => songs, [songs]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 360,
          border: 1,
          borderColor: "lightgrey",
        }}
      >
        <List disablePadding>
          {memoizedSongs.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem
                secondaryAction={<ListItemText primary={item.keyPref} />}
                disablePadding
              >
                <ListItemButton
                  selected={selectedIndex === index}
                  onClick={() =>
                    handleListItemClick(
                      item.id,
                      item.video,
                      item.key,
                      item.keyPref,
                      index
                    )
                  }
                >
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
              {index !== memoizedSongs.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default SongList;
