import React, { useEffect, useState } from "react";
import {
  Modal,
  Fade,
  Typography,
  IconButton,
  useMediaQuery,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IosShareIcon from "@mui/icons-material/IosShare";
import { QRCode } from "react-qrcode-logo";
import logo from "../images/logo.png";

const QRShareModal = ({ data }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [shrunkData, setShrunkData] = useState("");
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    shrinkData();
  }, [data]);

  const shrinkData = () => {
    const shrunk = btoa(data);
    setShrunkData(shrunk);
    return shrunk;
  };

  return (
    <>
      <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
        <Fade in={isModalOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "auto",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: isSmallScreen ? "10px" : "20px",
              borderRadius: "8px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                onClick={() => setModalOpen(false)}
                aria-label="close"
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            </Box>

            <QRCode
              value={shrunkData}
              enableCORS={true}
              qrStyle="square"
              quietZone={24}
              logoPadding={5}
              logoImage={logo}
              logoWidth={isSmallScreen ? 75 : 150}
              size={isSmallScreen ? 250 : 500}
            />

            <Typography>Comparte este QR code con todos.</Typography>
          </Box>
        </Fade>
      </Modal>
      <IconButton
        onClick={() => setModalOpen(true)}
        sx={{ mx: 1 }}
        size="large"
      >
        <IosShareIcon sx={{ transform: "scale(1.5)" }} />
      </IconButton>
    </>
  );
};

export default QRShareModal;
