import React, { useState, useEffect } from "react";
import { List, ListItem, ListItemText, Box } from "@mui/material";

import { db } from "../firebase";
import { get, ref as databaseRef } from "firebase/database";

const countOccurrences = (textArray) => {
  const occurrenceMap = {};
  textArray.forEach((text) => {
    occurrenceMap[text] = (occurrenceMap[text] || 0) + 1;
  });
  return Object.keys(occurrenceMap).map((key) => ({
    song: key,
    occurrence: occurrenceMap[key],
  }));
};

export default function VoteCount() {
  const [results, setResults] = useState([]);

  useEffect(() => {
    const voteRef = databaseRef(db, "votos");
    get(voteRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const arr = Object.values(snapshot.val()).map((item) => item.song);
          const voteCounts = countOccurrences(arr);
          setResults(voteCounts);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div style={{ backgroundColor: "white", minHeight: "100vh" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            border: "2px solid #000",
            width: { xs: 300, md: 500 },
            mt: 5,
          }}
        >
          <List>
            {results.map((item) => {
              return (
                <ListItem
                  key={item.song}
                  secondaryAction={
                    <ListItemText edge="end" primary={item.occurrence} />
                  }
                >
                  <ListItemText primary={item.song} />
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Box>
    </div>
  );
}
