import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

//Global Components
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import { useAuth } from "./context/AuthContext";

//Screens
import Home from "./screens/Home.js";
import Repository from "./screens/Repository.js";
import AdminLogin from "./screens/AdminLogin.js";
import ForgotPassword from "./screens/ForgotPassword";
import Generator from "./screens/Generator";
import OtherLists from "./screens/OtherLists";
import Vote from "./screens/Vote";
import VoteCount from "./screens/VoteCount";
import Calendar from "./screens/Calendar.js";
import SongDetail from "./screens/SongDetail.js";

// protects routes from end-users
const ProtectedRoute = ({ userID, children }) => {
  if (userID == "") {
    return <Navigate to="/" replace />;
  }

  return children;
};

// protects routes from admin users
const AdminProtectedRoute = ({ userID, children }) => {
  if (userID !== "") {
    return <Navigate to="/" replace />;
  }

  return children;
};

const App = () => {
  const { pathname } = useLocation();
  const { userID } = useAuth();

  return (
    <>
      {pathname !== "/adminlogin" && pathname !== "/forgotpassword" && (
        <ResponsiveAppBar />
      )}
      <Routes>
        <Route exact path="/adminlogin" element={<AdminLogin />} />
        <Route exact path="/forgotpassword" element={<ForgotPassword />} />
        <Route exact path="/" element={<Home />} />
        <Route exact path="/repository" element={<Repository />} />
        <Route exact path="/songdetail" element={<SongDetail />} />
        <Route exact path="/generator" element={<Generator />} />
        <Route exact path="/calendar" element={<Calendar />} />
        <Route exact path="/otherlists" element={<OtherLists />} />
        <Route
          exact
          path="/vote"
          element={
            <AdminProtectedRoute userID={userID}>
              <Vote />
            </AdminProtectedRoute>
          }
        />
        <Route
          exact
          path="/votecount"
          element={
            <ProtectedRoute userID={userID}>
              <VoteCount />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
};

export default App;
