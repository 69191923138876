// ScrollButton.js
import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

const ScrollButton = ({ targetRef, conditional, corner, breakpoint }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;

    switch (conditional) {
      case "GREATER":
        setIsVisible(scrollTop > breakpoint);
        break;

      case "LESS":
        setIsVisible(scrollTop < breakpoint);
        break;

      default:
        break;
    }
  };

  const scrollToView = () => {
    if (targetRef && targetRef.current) {
      targetRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Button
      variant="contained"
      sx={{
        position: "fixed",
        bottom: 16,
        left: corner == "right" ? "auto" : 16,
        right: corner == "right" ? 16 : "auto",
        display: isVisible ? "block" : "none",
        zIndex: 1,
        borderRadius: 20,
        padding: 1,
        minWidth: 0,
      }}
      onClick={scrollToView}
    >
      {corner == "right" ? (
        <ArrowCircleDownIcon sx={{ marginBottom: -0.75 }} fontSize="large" />
      ) : (
        <ArrowCircleUpIcon sx={{ marginBottom: -0.75 }} fontSize="large" />
      )}
    </Button>
  );
};

export default ScrollButton;
