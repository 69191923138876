import React, { useState, useEffect } from "react";
import { TextField, Button, CircularProgress } from "@mui/material";
import { db } from "../firebase";
import { get, ref as databaseRef } from "firebase/database";
import { useAuth } from "../context/AuthContext";
import { updateCalendarEvents } from "../utils/DBFunctions";
import "../Calendar.css";

const Calendar = () => {
  const { userID, isLoading, setLoading } = useAuth();
  const [events, setEvents] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const currentYear = new Date().getFullYear();
  const [newEvent, setNewEvent] = useState("");
  const [editModes, setEditModes] = useState([]);
  const [editedEventTitles, setEditedEventTitles] = useState([]);

  useEffect(() => {
    const fetchCalendarEvents = async () => {
      try {
        const calendarEventRef = databaseRef(db, "calendarEvents");
        setLoading(true);
        const snapshot = await get(calendarEventRef);
        if (snapshot.exists()) {
          setEvents(Object.values(snapshot.val()));
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchCalendarEvents();
  }, []);

  const handleDayClick = (day) => {
    setSelectedDay(day);
    setEditModes([]);
    setEditedEventTitles([]);
  };

  // Map through the events to find days with events
  const daysWithEvents = events.reduce((acc, event) => {
    acc[event.date] = (acc[event.date] || 0) + 1;
    return acc;
  }, {});

  // Function to handle changing the month
  const changeMonth = (input) => {
    const nextSixMonths = getNextSixMonths();

    setCurrentMonth((prevMonth) => {
      let monthIndex = nextSixMonths.indexOf(prevMonth) + input;

      if (monthIndex <= 0) return nextSixMonths[0];
      if (monthIndex > nextSixMonths.length - 1)
        return nextSixMonths[nextSixMonths.length - 1];

      return nextSixMonths[monthIndex];
    });
    setSelectedDay(null);
  };

  // Function to handle selecting a specific month
  const selectMonth = (event) => {
    const selectedMonth = parseInt(event.target.value, 10);
    if (!isNaN(selectedMonth) && selectedMonth >= 1 && selectedMonth <= 12) {
      setCurrentMonth(selectedMonth);
      setSelectedDay(null);
    }
  };

  // Render the calendar days
  const renderCalendarDays = () => {
    const totalDays = new Date(currentYear, currentMonth, 0).getDate(); // Get total days in the current month
    const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1).getDay(); // Get the day of the week for the first day
    const todaysDate = new Date()
      .toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "-");

    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const calendarDays = [];

    // Add the days of the week
    for (let i = 0; i < 7; i++) {
      calendarDays.push(
        <div key={`day-of-week-${i}`} className="day-of-week">
          {daysOfWeek[i]}
        </div>
      );
    }

    // Add empty days before the first day of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
      calendarDays.push(<div key={`empty-${i}`} className="empty-day" />);
    }

    // Add the calendar days
    for (let day = 1; day <= totalDays; day++) {
      const date = `${currentYear.toString()}-${currentMonth
        .toString()
        .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
      const hasEvents = daysWithEvents[date];

      calendarDays.push(
        <div
          key={day}
          className={`calendar-day ${selectedDay === date ? "selected" : ""} ${
            hasEvents ? "has-events" : ""
          } ${todaysDate == date ? "todays-date" : ""}`}
          onClick={() => handleDayClick(date)}
        >
          {day}
        </div>
      );
    }

    return calendarDays;
  };

  const handleAddEvent = () => {
    setLoading(true);
    if (selectedDay && newEvent.trim() !== "") {
      const newEventObject = { date: selectedDay, title: newEvent };
      setEvents([...events, newEventObject]);
      updateCalendarEvents([...events, newEventObject], setLoading);
      setNewEvent("");
      setEditModes([...editModes, false]);
    }
  };

  const handleEditEvent = (eventIndex, itemIndex) => {
    setEditModes((prevModes) => {
      const newModes = [...prevModes];
      newModes[eventIndex] = true;
      return newModes;
    });

    setEditedEventTitles((prevTitles) => {
      const newTitles = [...prevTitles];
      newTitles[eventIndex] = events[itemIndex].title;
      return newTitles;
    });
  };

  const handleSaveEvent = (eventIndex, itemIndex) => {
    setLoading(true);
    const updatedEvents = [...events];
    updatedEvents[itemIndex].title = editedEventTitles[eventIndex];
    setEvents(updatedEvents);
    updateCalendarEvents(updatedEvents, setLoading);

    setEditModes((prevModes) => {
      const newModes = [...prevModes];
      newModes[eventIndex] = false;
      return newModes;
    });
  };

  const handleDeleteEvent = (eventIndex, itemIndex) => {
    setLoading(true);
    const updatedEvents = [...events];
    updatedEvents.splice(itemIndex, 1);
    setEvents(updatedEvents);
    updateCalendarEvents(updatedEvents, setLoading);

    setEditModes((prevModes) => {
      const newModes = [...prevModes];
      newModes.splice(eventIndex, 1);
      return newModes;
    });
    setEditedEventTitles((prevTitles) => {
      const newTitles = [...prevTitles];
      newTitles.splice(eventIndex, 1);
      return newTitles;
    });
  };

  // Render the events for the selected day
  const renderEvents = () => {
    if (!selectedDay) {
      return <p>Seleccione un día para ver eventos.</p>;
    }

    const selectedEvents = events.filter((event) => event.date === selectedDay);

    const mydate = new Date(selectedDay);

    // Format the date as "January 12, 2024"
    const formattedDate = mydate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC",
    });

    return (
      <div>
        <h2>Eventos en {formattedDate}</h2>
        {selectedEvents.map((event, index) => (
          <div key={index} className="event-container">
            {editModes[index] ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  type="text"
                  multiline
                  value={editedEventTitles[index]}
                  onChange={(e) => {
                    const newTitles = [...editedEventTitles];
                    newTitles[index] = e.target.value;
                    setEditedEventTitles(newTitles);
                  }}
                />
                <div>
                  <Button
                    variant="contained"
                    onClick={() =>
                      setEditModes((prevModes) => {
                        const newModes = [...prevModes];
                        newModes[index] = false;
                        return newModes;
                      })
                    }
                    sx={{ mx: 1, my: 2 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() =>
                      handleSaveEvent(
                        index,
                        events.findIndex(
                          (item) =>
                            item.title === event.title &&
                            item.date === event.date
                        )
                      )
                    }
                    sx={{ mx: 1, my: 2 }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <p
                  style={{ whiteSpace: "pre-line", fontSize: "20px" }}
                  className="event-title"
                >
                  {event.title}
                </p>
                {userID !== "" && (
                  <div>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        handleEditEvent(
                          index,
                          events.findIndex(
                            (item) =>
                              item.title === event.title &&
                              item.date === event.date
                          )
                        )
                      }
                      sx={{ mx: 1, my: 2 }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        if (
                          confirm("¿Estás seguro de que quieres eliminar?") ==
                          true
                        ) {
                          handleDeleteEvent(
                            index,
                            events.findIndex(
                              (item) =>
                                item.title === event.title &&
                                item.date === event.date
                            )
                          );
                        }
                      }}
                      sx={{ mx: 1, my: 2 }}
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const getNextSixMonths = () => {
    const months = [];
    let current = new Date().getMonth() + 1;

    for (let i = 0; i < 6; i++) {
      months.push(current);
      current = (current % 12) + 1;
    }

    return months;
  };

  return (
    <div className="calendar-container">
      <center>
        <div className="calendar">
          <div className="calendar-header">
            <Button variant="outlined" onClick={() => changeMonth(-1)}>
              Previous
            </Button>
            <select onChange={selectMonth} value={currentMonth}>
              {getNextSixMonths().map((month) => (
                <option key={month} value={month}>
                  {new Date(currentYear, month - 1, 1).toLocaleString(
                    "default",
                    {
                      month: "long",
                    }
                  )}
                </option>
              ))}
            </select>
            <Button variant="outlined" onClick={() => changeMonth(1)}>
              Next
            </Button>
          </div>
          <div className="calendar-days">{renderCalendarDays()}</div>
        </div>
        {isLoading && <CircularProgress />}
      </center>
      <div className="event-list-container">
        <div className="event-list">
          {renderEvents()}
          {selectedDay && userID !== "" && (
            <div
              className="add-event-container"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <TextField
                type="text"
                multiline
                value={newEvent}
                onChange={(e) => setNewEvent(e.target.value)}
                placeholder="Add new event..."
              />
              <div>
                <Button
                  variant="contained"
                  onClick={handleAddEvent}
                  sx={{ mx: 1, my: 2 }}
                >
                  Add Event
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
