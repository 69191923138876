import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  CircularProgress,
  Button,
  MenuItem,
  FormControl,
  Select,
  TextField,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import moment from "moment";
import ChordSheetJS from "chordsheetjs";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { Buffer } from "buffer";
import RefreshIcon from "@mui/icons-material/Refresh";

import { useAuth } from "../context/AuthContext";

//Firebase imports
import { db } from "../firebase";
import { ref, get, child } from "firebase/database";
import {
  addOtherListItem,
  updateOtherListItem,
  deleteOtherListItem,
  updateDBList,
  updateOtherListSongNotes,
  updateDBListSongMemos,
} from "../utils/DBFunctions";

//Component imports
import DraggableSongList from "../components/DraggableSongList";
import ModalRepo from "../components/ModalRepo";
import SongList from "../components/SongList";
import NotesModal from "../components/NotesModal";
import MemoModal from "../components/MemoModal";
import FontSizeAdjuster from "../components/FontSizeAdjuster";
import ScrollButton from "../components/ScrollButton";
import LastModifiedByText from "../components/LastModifiedByText";

export default function OtherLists() {
  const [listOptions, setListOptions] = useState([]);
  const [currList, setCurrList] = useState("");
  const [dateSelected, setDateSelected] = useState(moment());
  const [songs, setSongs] = useState([]);
  const [oldSongsArr, setOldSongsArr] = useState([]);
  const [document, setDocument] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [currKey, setCurrKey] = useState("C");
  const [keyPref, setKeyPref] = useState("C");
  const [listTitle, setListTitle] = useState("");
  const [newListTitle, setNewListTitle] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [notesVisible, setNotesVisible] = useState(false);
  const [memoVisible, setMemoVisible] = useState(false);
  const currListItemID = useRef("");
  const oldListItemID = useRef("");
  const [LastAuthorEmail, setLastAuthorEmail] = useState("N/A");
  const [LastAuthorTime, setLastAuthorTime] = useState("N/A");
  const [chordSheet, setChordSheet] = useState("");
  const [notes, setNotes] = useState([]);
  const [memos, setMemos] = useState([]);
  const [chordSheetFontSize, setChordSheetFontSize] = useState("14px");
  const chordSheetRef = useRef();
  const songListRef = useRef();
  const videoRef = useRef();

  const { userID, auth } = useAuth();

  const toggleNotes = () => setNotesVisible(!notesVisible);
  const toggleMemo = () => setMemoVisible(!memoVisible);

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:601px) and (max-width:900px)"
  );

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    get(ref(db, "coleccionDeListas")).then((snapshot) => {
      if (snapshot.exists()) {
        setListOptions(Object.values(snapshot.val()));
      }
    });
    get(ref(db, "LastModifiedData")).then((snapshot) => {
      if (snapshot.exists()) {
        setLastAuthorEmail(snapshot.val().otherListLastAuthor);
        setLastAuthorTime(snapshot.val().otherListLastTime);
      }
    });
  }, []);

  const setAsCurrentList = async () => {
    try {
      setLoading(true);
      updateDBList(songs, (choice) => setLoading(choice), LastAuthorEmail);
    } catch (error) {
      console.error(error);
    }
  };

  function dataReset() {
    currListItemID.current = "";
    oldListItemID.current = "";
    setSongs([]);
    setNotes([]);
    setMemos([]);
    setOldSongsArr([]);
    setDocument("");
    setCurrList("");
    setListTitle("");
    setChordSheet("");
    setSelectedIndex(0);
  }

  const handleListClick = useCallback((listItem) => {
    setLoading(true);

    dataReset();

    currListItemID.current = listItem.id;
    oldListItemID.current = listItem.id;

    Object.values(listItem.songs).map((item, index) => {
      get(child(ref(db), "articulos" + `/${item.id}`))
        .then(async (snapshot) => {
          if (snapshot.exists()) {
            const songObj = { ...snapshot.val() };
            songObj.keyPref = item.keyPref;
            setSongs((current) => [...current, songObj]);
            setOldSongsArr((current) => [...current, songObj]);
            setNotes((current) => [
              ...current,
              { id: item.id, notes: item.notes ?? "" },
            ]);

            // setting user memos
            const memoText = await get(
              child(ref(db), `usuarios/${userID}/memos/${item.id}`)
            );
            const newObj = {
              [item.id]: memoText.val() ? memoText.val() : "",
            };
            setMemos((prevArray) => [...prevArray, newObj]);

            if (index === 0) {
              // setting document here
              try {
                const response = await fetch(snapshot.val().file);
                if (!response.ok) {
                  throw new Error("Network response was not ok");
                }

                const textContent = await response.text();
                const buff = Buffer.from(textContent, "utf-8");
                const base64Content = buff.toString("base64");

                setDocument(base64Content);
                setLoading(false);
              } catch (error) {
                console.error("Error fetching or saving the file:", error);
              }

              setCurrKey(snapshot.val().key);
              setKeyPref(item.keyPref);
              setVideoLink(snapshot.val().video);
            }
          } else {
            console.log("No data available.");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    });
    setListTitle(listItem.title);
    setChordSheetFontSize(
      isSmallScreen ? "14px" : isMediumScreen ? "20px" : "26px"
    );
    setLoading(false);
  });

  useEffect(() => {
    if (document !== "") {
      // create a buffer
      const buff = Buffer.from(document, "base64");

      // decode buffer as UTF-8
      const text = buff.toString("utf-8");
      try {
        let parser = new ChordSheetJS.UltimateGuitarParser();
        let chordSheet = parser.parse(text);
        let currChordSheet = chordSheet.setKey(currKey);
        let newSheet = currChordSheet.changeKey(keyPref);
        const formatter = new ChordSheetJS.TextFormatter();
        const outputText = formatter.format(newSheet);
        setLoading(false);
        setChordSheet(outputText);
      } catch (error) {
        setLoading(false);
        console.error("Error parsing chord sheet:", error);
      }
    }
  }, [document]);

  return (
    <div
      style={{
        backgroundColor: "white",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <ModalRepo
        sendItemCB={(item) => {
          const songObj = { ...item };
          songObj.keyPref = item.key;
          setSongs((current) => [...current, songObj]);
        }}
        open={open}
        setOpen={setOpen}
      />
      <ScrollButton
        targetRef={songListRef}
        conditional={"GREATER"}
        corner={"left"}
        breakpoint={320}
      />
      <ScrollButton
        targetRef={videoVisible ? videoRef : chordSheetRef}
        conditional={"LESS"}
        corner={"right"}
        breakpoint={320}
      />
      <IconButton
        onClick={() => location.reload()}
        sx={{ mt: 1, position: "absolute", top: 170, left: 15 }}
        size="medium"
      >
        <RefreshIcon sx={{ transform: "scale(1.5)" }} />
      </IconButton>
      <Box sx={{ display: "flex", justifyContent: "center", py: 3 }}>
        {userID !== "" && (
          <IconButton
            disabled={currList == "" || editMode}
            onClick={() => {
              if (confirm("¿Estas seguro en remover esta lista?")) {
                deleteOtherListItem(
                  currListItemID.current,
                  (choice) => setLoading(choice),
                  auth.currentUser.email
                );
              }
            }}
          >
            <DeleteIcon
              style={{
                color: currList == "" || editMode ? "black" : "red",
                fontSize: 30,
              }}
            />
          </IconButton>
        )}
        <FormControl sx={{ minWidth: 240, mx: 1 }}>
          <Select
            displayEmpty
            value={currList}
            onChange={(event) => setCurrList(event.target.value)}
            disabled={editMode}
          >
            <MenuItem disabled value="">
              <em>Escoge Lista</em>
            </MenuItem>
            {listOptions.map((item) => (
              <MenuItem
                key={item.date}
                value={item.date}
                onClick={() => handleListClick(item)}
              >
                {item.date}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {userID !== "" && (
          <IconButton
            disabled={editMode}
            onClick={() => {
              dataReset();
              setEditMode(true);
            }}
          >
            <AddCircleIcon
              style={{ color: editMode ? "black" : "green", fontSize: 30 }}
            />
          </IconButton>
        )}
      </Box>
      {editMode && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 5,
          }}
        >
          <MobileDatePicker
            label="Escoge Fecha"
            inputFormat="MM/DD/YYYY"
            value={dateSelected}
            onChange={(newValue) => setDateSelected(newValue)}
            slotProps={{ textField: { variant: "outlined" } }}
          />
        </Box>
      )}
      {!editMode && (
        <Box
          ref={songListRef}
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 2,
            flexDirection: isSmallScreen ? "column" : "row", // Initially set to column layout
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Button
              variant="contained"
              onClick={() => setVideoVisible(false)}
              sx={{
                mx: 2,
                backgroundColor: !videoVisible ? "green" : "grey",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
            >
              Acordes
            </Button>
            <Button
              variant="contained"
              onClick={() => setVideoVisible(true)}
              sx={{
                mx: 2,
                backgroundColor: videoVisible ? "green" : "grey",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
            >
              Video
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: isSmallScreen ? 2 : 0,
            }}
          >
            <Button
              variant="contained"
              onClick={toggleNotes}
              sx={{
                mx: 2,
              }}
            >
              Notas
            </Button>

            {userID != "" && (
              <Button
                variant="contained"
                onClick={toggleMemo}
                sx={{
                  mx: 2,
                  backgroundColor: "#EE6352",
                  "&:hover": {
                    backgroundColor: "#C65445",
                  },
                }}
              >
                Memo
              </Button>
            )}
          </Box>
        </Box>
      )}

      <NotesModal
        item={notes.find((obj) => obj.id === oldSongsArr[selectedIndex].id)}
        isEditable={userID !== ""}
        isOpen={notesVisible}
        onClose={toggleNotes}
        currListItemID={currListItemID.current}
        saveNotesCB={(item) => {
          const storedNotes = notes.find(
            (obj) => obj.id === songs[selectedIndex].id
          );

          if (storedNotes === undefined) {
            setNotes((current) => [
              ...current,
              { id: songs[selectedIndex].id, notes: item },
            ]);
          } else {
            storedNotes.notes = item;
          }

          try {
            updateOtherListSongNotes(
              currListItemID.current,
              songs[selectedIndex].id,
              item
            );
            alert("¡Los datos han sido añadidos!");
          } catch (error) {
            console.log(error);
          }

          toggleNotes();
        }}
      />

      <MemoModal
        item={
          memos?.find((obj) => oldSongsArr[selectedIndex]?.id in obj)?.[
            oldSongsArr[selectedIndex]?.id
          ] ?? ""
        }
        isOpen={memoVisible}
        onClose={toggleMemo}
        currListItemID={currListItemID.current}
        saveMemoCB={(item) => {
          const resultObject = memos.find(
            (obj) => songs[selectedIndex].id in obj
          );
          const indexToUpdate = memos.findIndex(
            (obj) => songs[selectedIndex].id in obj
          );
          const storedMemos = resultObject
            ? resultObject[songs[selectedIndex].id]
            : null;

          if (storedMemos === null) {
            const newObj = { [songs[selectedIndex].id]: item };
            setMemos((prevArray) => [...prevArray, newObj]);
          } else {
            memos[indexToUpdate][songs[selectedIndex].id] = item;
          }

          try {
            updateDBListSongMemos(songs[selectedIndex].id, item, userID);
            alert("¡Los datos han sido añadidos!");
          } catch (error) {
            console.log(error);
          }

          toggleMemo();
        }}
      />

      {editMode && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 2,
          }}
        >
          <TextField
            label="Titulo de La Lista (Opcional)"
            sx={{ width: 360 }}
            value={newListTitle}
            onChange={(event) => setNewListTitle(event.target.value)}
          />
        </Box>
      )}

      {!editMode && (
        <Typography
          ref={songListRef}
          sx={{ pb: 2, textAlign: "center" }}
          variant="h5"
        >
          {listTitle}
        </Typography>
      )}
      {!editMode && (
        <SongList
          setLoading={setLoading}
          songs={songs}
          setDocument={setDocument}
          setCurrKey={setCurrKey}
          setKeyPref={setKeyPref}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          setVideoLink={setVideoLink}
        />
      )}
      {editMode && userID !== "" && (
        <Box sx={{ display: "flex", justifyContent: "center", mb: 5 }}>
          <Button variant="contained" onClick={() => setOpen(true)}>
            Add
          </Button>
        </Box>
      )}
      {editMode && userID !== "" && (
        <DraggableSongList songs={songs} setSongs={setSongs} />
      )}
      {!editMode && userID !== "" && songs.length !== 0 && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 30,
            marginBottom: 30,
          }}
        >
          <Button
            variant="contained"
            sx={{
              mx: 2,
              backgroundColor: "GhostWhite",
              "&:hover": {
                backgroundColor: "Gainsboro",
              },
              color: "gray",
            }}
            onClick={() => {
              if (
                confirm(
                  "¿Estás seguro de que quieres transferir esta lista al Lista Actual? ¡Todos los datos de la lista actual se eliminaran!"
                )
              ) {
                setAsCurrentList();
              }
            }}
          >
            ⬅ Transfer
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setDateSelected(moment(new Date(currList)));
              setNewListTitle(listTitle);
              setEditMode(true);
            }}
          >
            Edit
          </Button>
        </Box>
      )}

      {editMode && userID !== "" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: 360,
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "grey",
                "&:hover": {
                  backgroundColor: "dimgrey",
                },
              }}
              onClick={() => {
                setSongs(oldSongsArr);
                currListItemID.current = oldListItemID.current;
                setEditMode(false);
                setNewListTitle("");
              }}
            >
              Cancel
            </Button>
            {editMode && isLoading && <CircularProgress />}
            <Button
              variant="contained"
              disabled={songs.length == 0}
              sx={{
                backgroundColor: "green",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
              onClick={
                currListItemID.current !== ""
                  ? () =>
                      updateOtherListItem(
                        {
                          id: currListItemID.current,
                          date: dateSelected.format("MM/DD/YYYY"),
                          songs: songs,
                          otherListLastAuthor: auth.currentUser.email,
                          title: newListTitle,
                        },
                        (choice) => setLoading(choice)
                      )
                  : () =>
                      addOtherListItem(
                        {
                          date: dateSelected.format("MM/DD/YYYY"),
                          songs: songs,
                          otherListLastAuthor: auth.currentUser.email,
                          title: newListTitle,
                        },
                        (choice) => setLoading(choice)
                      )
              }
            >
              Save
            </Button>
          </Box>
        </Box>
      )}

      {userID !== "" && (
        <LastModifiedByText email={LastAuthorEmail} time={LastAuthorTime} />
      )}

      {!editMode && (
        <FontSizeAdjuster
          initialFontSize={parseInt(
            (isSmallScreen
              ? "14px"
              : isMediumScreen
              ? "20px"
              : "26px"
            ).substring(0, 2)
          )}
          currFontSize={parseInt(chordSheetFontSize.substring(0, 2))}
          setFontSize={setChordSheetFontSize}
        />
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          mt: 3,
          pb: 3,
        }}
      >
        {!editMode && isLoading && (
          <CircularProgress style={{ marginBottom: "20px" }} />
        )}
        {!editMode && !videoVisible && (
          <TextareaAutosize
            ref={chordSheetRef}
            placeholder="Vacio"
            readOnly
            value={chordSheet}
            onChange={(event) => {
              setChordSheet(event.target.value);
            }}
            style={{
              width: "90vw",
              maxWidth: isSmallScreen
                ? "600px"
                : isMediumScreen
                ? "750px"
                : "900px",
              padding: "12px",
              marginBottom: 50,
              borderRadius: "6px",
              resize: "none",
              fontSize: chordSheetFontSize,
              fontFamily: "Roboto Mono, monospace",
            }}
          />
        )}
        {!editMode && videoVisible && (
          <Box ref={videoRef} sx={{ maxWidth: { xs: 360, md: 720 }, mb: 10 }}>
            <iframe
              onLoad={() => setLoading(false)}
              onError={() => setLoading(false)}
              style={{ width: "100%", height: "360px" }}
              src={videoLink}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </Box>
        )}
      </Box>
    </div>
  );
}
